<template>
  <div class="row justify-content-center" :style="`font-family:${font},sans-serif`">
    <div class="card col-sm-12 col-md-12 p-1">
      <div>
        <div>
          <div class="title head">
            <span class="h4" style="color: #558cef"> สรุปการรับเข้าเบิกจ่ายคงเหลือวัสดุ </span>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-center">
            <b-form-group class="col-12 col-md-6">
              <div class="col-12">
                <label class="mr-1 col-2">จากวันที่</label>
                <v-date-picker
                  id="example-datepicker"
                  label="วันที่รับ:"
                  locale="th-TH"
                  v-model="date1"
                  @update:from-page="getHeaderInputDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-input :value="formatDate(inputValue, 'startdate')" v-on="inputEvents" />
                  </template>
                  <template #header-title>
                    {{ headerInputDate }}
                  </template>
                </v-date-picker>
              </div>
            </b-form-group>
            <b-form-group class="col-12 col-md-5">
              <div class="col-12">
                <label class="mr-1 col-2">ถึงวันที่</label>
                <v-date-picker
                  id="example-datepicker"
                  label="วันที่รับ:"
                  locale="th-TH"
                  v-model="date2"
                  @update:from-page="getHeaderInputDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-input :value="formatDate(inputValue, 'enddate')" v-on="inputEvents" />
                  </template>
                  <template #header-title>
                    {{ headerInputDate }}
                  </template>
                </v-date-picker>
              </div>
            </b-form-group>
            <div class="mt-2">
              <b-button variant="outline-primary" @click="dateField">เเสดง</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-sm-12 col-md-12 p-1">
      <div>
        <div class="title head">
          <p class="h2" style="color: #558cef">วัสดุคงคลัง</p>
        </div>
        <hr />

        <!-- search input -->
        <div class="custom-search row justify-content-between">
          <b-form-group class="col-12 col-md-6 mt-1"> </b-form-group>
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา</label>
              <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
              <div>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>

                  <b-dropdown-item @click="exportExcel">
                    <span>
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportPDF">
                    <span>
                      <feather-icon icon="FileIcon" class="mr-50" />
                      PDF
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">of {{ total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";

import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import { DatePicker } from "v-calendar";
const dayjs = require("dayjs");
export default {
  components: {
    vSelect,
    BButton,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },

  data() {
    // const date = new Date()
    return {
      headerInputDate: "",
      date1: new Date(),
      date2: new Date(),
      date: {
        startDate: "",
        endDate: "",
      },
      show: false,
      total: "",
      pageLength: 50,
      currentPage: 1,
      dir: false,
      columns: [
        {
          label: "หมายเลขวัสดุ",
          field: "m_id",
        },
        {
          label: "ชื่อกลุ่มวัสดุ",
          field: "m_gname",
        },
        {
          label: "ชื่อวัสดุ",
          field: "m_name",
          thClass: "text-center",
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",

          sortable: true,
          type: "number",
          formatFn: this.formatFn,
        },
        {
          label: "รับเข้า",
          field: "amount",
          type: "number",
          formatFn: this.formatFn,
        },
        {
          label: "เบิก",
          field: "request",
          type: "number",
          formatFn: this.formatFn,
        },
        {
          label: "คงเหลือ",
          field: "remain",
          type: "number",
          formatFn: this.formatFn,
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    
    this.get_data_table();
    // this.checkLogout();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    examinedate() {},
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      console.log(date);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "startdate":
            this.date.startDate = dete_val;

            break;
          case "enddate":
            this.date.endDate = dete_val;

            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    exportPDF() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Medium.ttf",
          bold: "Sarabun-Regular.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [5, 60, 5, 5],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "วัสดุคงคลัง",
        },
        header: [
          { text: "วัสดุคงคลัง", style: "header", margin: [0, 30, 0, 0] },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
          },
        ],

        content: [
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: [36, "auto", "auto", "*", 50, 100, "auto", "auto", "auto"],
              body: [
                [
                  { text: "ลำดับที่", style: "tableHeader" },
                  { text: "หมายเลขวัสดุ", style: "tableHeader" },
                  {
                    text: "ชื่อกลุ่มวัสดุ",
                    style: "tableHeader",
                  },
                  { text: "ชื่อวัสดุ", style: "tableHeader", width: "50" },
                  { text: "หน่วยนับ", style: "tableHeader" },
                  { text: "ราคาต่อหน่วย", style: "tableHeader" },
                  { text: "รับเข้า", style: "tableHeader" },
                  { text: "เบิก", style: "tableHeader" },
                  { text: "คงเหลือ", style: "tableHeader" },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: index + 1, style: "text_center" },
                    { text: res.m_id, style: "text_left" },
                    { text: res.m_gname, style: "text_left" },
                    { text: res.m_name, style: "text_left" },
                    { text: res.unittype, style: "text_left" },
                    {
                      text: Number(res.unitprice)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                      style: "text_number",
                    },
                    { text: res.amount, style: "text_number" },
                    { text: res.request, style: "text_number" },

                    { text: res.remain, style: "text_number" },
                  ];
                }),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 5, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 9,
          },
          text_left: {
            alignment: "left",
            fontSize: 9,
          },
          text_number: {
            fontSize: 9,
            alignment: "right",
          },
          text: {
            fontSize: 9,
            margin: [0, 0, 0, 0],
          },
        },
      };
      pdfMake.createPdf(docDefinition).download("วัสดุคงคลัง");
    },
    formatFn: function (value) {
      return value
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    dateField: async function () {
      console.log(this.date.startDate);
      console.log(this.date.endDate);
      const _checkdate = dayjs(this.date.startDate).isAfter(this.date.endDate);
      console.log(_checkdate);
      if (_checkdate === true) {
        Swal.fire({
          icon: "danger",
          title: "กรุณาเลือกวันที่ใหม่!",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
        return;
      }

      this.$router.push({
        name: "inrowwasadu",
        params: {
          rowData: {
            startDate: this.date.startDate,
            endDate: this.date.endDate,
          }, // or anything you want
        },
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "วัสดุคงคลัง",
        columns: [
          {
            title: "วัสดุคงคลัง",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "หมายเลขวัสดุ",
                field: "m_id",
              },
              {
                title: "ชื่อกลุ่มวัสดุ",
                field: "m_gname",
              },
              {
                title: "ชื่อวัสดุ",
                field: "m_name",
                thClass: "text-center",
              },
              {
                title: "หน่วยนับ",
                field: "unittype",
              },
              {
                title: "ราคาต่อหน่วย",
                field: "unitprice",
                type: "number",
              },
              {
                title: "รับเข้า",
                field: "amount",
              },
              {
                title: "เบิก",
                field: "request",
              },
              {
                title: "คงเหลือ",
                field: "remain",
              },
            ],
          },
        ],
      });
    },
    pageChanged(event) {
      console.log(event);
      this.currentPage = event.currentPage;
      this.get_data_table();
    },
    perPageChanged(event) {
      console.log(event);
      this.pageLength = event.currentPerPage;
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}materialInStore?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=1`;
      console.log(url);
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message);
      this.total1 = res.data.message.total;
      let idx = Math.ceil(Number(this.total1) / 100);
      let res_pdf = [];
      for (let index = 1; index <= idx; index++) {
        const url_pdf = `${API}materialInStore?_page=${index}&_limit=100&_sort=1`;
        console.log(url_pdf);
        const res_pdfs = await axios.get(url_pdf, header);
        console.log(res_pdfs);
        const { data } = res_pdfs.data.message;
        res_pdf.push(...data);
      }
      this.rows = res_pdf.map((el) => {
        return {
          ...el,
          m_name: el.m_name.split(" - ")[1],
        };
      });
      this.total = res.data.message.total;
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
